import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "header-iconbar__favorite-icon" }
const _hoisted_2 = {
  x: "13",
  y: "17",
  "text-anchor": "middle",
  "dominant-baseline": "top"
}

import type { FavoritedHotel } from '@/interfaces/api/v1-hotel-list';
import { getWishlistHotels, updateStorage, triggerFavoriteChange, migrateWishlistPersistence } from '@/js/modules/bookmark';
import { EventBus } from '@global-js/event-bus';
import type { Ref } from 'vue';
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import BookmarkAnalytics from '@/js/modules/analytics/BookmarkAnalytics';
import * as hotelListService from '@services/hotelListService';
import HeaderFavoriteDropdown from './HeaderFavoriteDropdown.vue';
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderFavoriteButton',
  setup(__props) {

const headerFavoriteDropdown = ref<InstanceType<typeof HeaderFavoriteDropdown> | null>(null);

const root = ref<InstanceType<typeof HTMLDivElement> | null>(null);

const showDropdown = ref(false);

const hotels: Ref<FavoritedHotel[]> = ref([]);

const updateCookieHotels = (apiHotels: FavoritedHotel[]) => {
	const analytics = BookmarkAnalytics.fromFavoritedHotel(apiHotels);
	analytics.fireViewCart();
	if (apiHotels.length !== hotels.value.length) {
		updateStorage(apiHotels);
		triggerFavoriteChange();
	}
};

const requestAndUpdateHotels = async () => {
	if (hotels.value.length) {
		try {
			const hotelIds = hotels.value.map((h: FavoritedHotel) => Number(h.IffCode));
			updateCookieHotels(await hotelListService.get(hotelIds));
		} catch (e) {
			if (e instanceof Error) {
				console.error(e.message);
			}
		}
	}
};

const closeFavorite = (event: MouseEvent) => {
	const target = event.target as HTMLElement;
	if (!headerFavoriteDropdown.value?.$el.contains(target)) {
		document.body.classList.remove('header-favorite-open');
		document.body.removeEventListener('click', closeFavorite, true);
		showDropdown.value = false;
		if (root.value?.contains(target)) {
			event.stopPropagation();
		}
	}
	document.body.classList.remove('noScrollMobile');
};

const openFavorite = () => {
	document.body.classList.add('header-favorite-open');
	if (document.body.classList.contains('search-open')) {
		document.body.classList.remove('page-header--hidden'); // Fixes vanishing of headerbar
	}
	showDropdown.value = true;
	requestAndUpdateHotels();
	document.body.addEventListener('click', closeFavorite, true);
	document.body.classList.add('noScrollMobile');
};

const updateHotel = () => {
	getWishlistHotels().then((hotelsCookie) => {
		hotels.value = hotelsCookie.hotels;
	});
};

onBeforeMount(() => {
	migrateWishlistPersistence();
	updateHotel();
	EventBus.$on('bookmark:favoriteChange', updateHotel);
});

onBeforeUnmount(() => {
	EventBus.$off('bookmark:favoriteChange', updateHotel);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "root",
    ref: root,
    class: "header-iconbar__entry-wrapper"
  }, [
    _createElementVNode("button", {
      class: "header-iconbar__entry header-iconbar__entry-favorite",
      onClick: _withModifiers(openFavorite, ["prevent"])
    }, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "header-iconbar__favorite-icon--close" }, null, -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(FaIcon, {
          icon: ['fa-solid', 'fa-heart'],
          class: _normalizeClass(["header-iconbar__favorite-icon-heart", { 'header-iconbar__favorite-icon-heart--invisible': showDropdown.value }])
        }, null, 8 /* PROPS */, ["class"]),
        (_openBlock(), _createElementBlock("svg", {
          class: _normalizeClass(["header-iconbar__favorite-icon-count", { 'header-iconbar__favorite-icon-count--visible': hotels.value.length }]),
          width: "24",
          height: "18",
          viewBox: "0 0 30 30"
        }, [
          _cache[0] || (_cache[0] = _createElementVNode("circle", {
            cx: "12",
            cy: "12",
            r: "11"
          }, null, -1 /* HOISTED */)),
          _createElementVNode("text", _hoisted_2, _toDisplayString(hotels.value.length), 1 /* TEXT */)
        ], 2 /* CLASS */))
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "header-iconbar__entry-label" }, "Merkzettel", -1 /* HOISTED */))
    ]),
    _createVNode(HeaderFavoriteDropdown, {
      ref_key: "headerFavoriteDropdown",
      ref: headerFavoriteDropdown,
      "show-dropdown": showDropdown.value,
      hotels: hotels.value
    }, null, 8 /* PROPS */, ["show-dropdown", "hotels"])
  ], 512 /* NEED_PATCH */))
}
}

})